export const WARNING_TITLE = "WARNING - ";
export const WARNING_TEXT =
  "You are assessing technical data whose export is restricted by the Arms Export Control Act (Title 22, U.S.C., Sec 2751, et seq.) or the Export Administration Act of 1979, as amended (Title 50, U.S.C., App. 2401 et seq.). Violations of these export laws are subject to severe criminal penalties.";
export const WARNING_TITLE_AND_TEXT =
  "WARNING - You are assessing technical data whose export is restricted by the Arms Export Control Act (Title 22, U.S.C., Sec 2751, et seq.) or the Export Administration Act of 1979, as amended (Title 50, U.S.C., App. 2401 et seq.). Violations of these export laws are subject to severe criminal penalties.";

export const COMPOSITE = "composite";
export const INDICATORS = "Indicators";
export const OVERLAYS = "Overlays";

export const DEFAULT_OVERLAY_OPACITY = 70;

export const SelectionMode = Object.freeze({
  Default: "Default Mode",
  MultiRegions: "Multi Regions",
  MultiIndicators: "Multi Indicators",
});

export const PRIMARY_LAYER_LINE_COLOR = "#fff";

export const GRAPH_COLOR_SCALE = [
  "#8dd3c7",
  "#bebada",
  "#fb8072",
  "#80b1d3",
  "#fdb462",
  "#b3de69",
  "#fccde5",
  "#d9d9d9",
  "#bc80bd",
  "#ccebc5",
  "#ffed6f",
];

// Number of available colors for selections limited by length of color scale,
// one value of which already used for Composite % Variance trendline in graph
export const MAX_SELECTION_COUNT = GRAPH_COLOR_SCALE.length - 1;
